// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/codebuild/output/src118859236/src/inplay-docs/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("/codebuild/output/src118859236/src/inplay-docs/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-glossary-js": () => import("/codebuild/output/src118859236/src/inplay-docs/src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src118859236/src/inplay-docs/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-odds-definitions-js": () => import("/codebuild/output/src118859236/src/inplay-docs/src/pages/odds-definitions.js" /* webpackChunkName: "component---src-pages-odds-definitions-js" */),
  "component---src-pages-privacy-policy-js": () => import("/codebuild/output/src118859236/src/inplay-docs/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-responsible-gambling-js": () => import("/codebuild/output/src118859236/src/inplay-docs/src/pages/responsible-gambling.js" /* webpackChunkName: "component---src-pages-responsible-gambling-js" */),
  "component---src-pages-rules-js": () => import("/codebuild/output/src118859236/src/inplay-docs/src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-pages-security-js": () => import("/codebuild/output/src118859236/src/inplay-docs/src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-terms-of-use-js": () => import("/codebuild/output/src118859236/src/inplay-docs/src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

